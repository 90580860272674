.theme-kida {
    background: #121212;
    color: white;
    
    font-family: 'Ubuntu', sans-serif !important;
    font-weight: 300 !important;
    font-size: 1rem !important;
    line-height: 1.4 !important;
    letter-spacing: .6px !important;
    -ms-overflow-style: scrollbar;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // theme layout
    a {
        color: white;
        text-decoration: none;
    }
    
    strong,
    b {
        font-weight: 600 !important;
    }
    
    p {
        margin: 0 0 2.5rem 0;
    }
    
    h1 {
        font-size: 54px !important;
        font-weight: 800 !important;
        line-height: 1.4 !important;
        margin: 0 0 1.5rem 0;
    }
    
    h2 {
        font-size: 42px !important;
        font-weight: 700 !important;
        line-height: 1.4 !important;
        margin: 0 0 1.5rem 0;
    }
    
    h3 {
        font-size: 28px !important;
        font-weight: 500 !important;
        text-transform: capitalize !important;
        margin: 0 0 1.5rem 0;
    }
    
    h4 {
        font-size: 30px !important;
        font-weight: 400 !important;
        text-transform: capitalize !important;
        margin: 0 0 1.5rem 0;
    }
    
    h5 {
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 1.4 !important;
        margin: 0 0 1.5rem 0;
    }
    
    h6 {
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 1.4 !important;
        margin: 0 0 1.5rem 0;
    }

    footer > .footer-container,
    section.container {
        max-width: 900px;
        padding: 15px;
    }
    
    .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    #logo {
        width: 75px;
        height: 75px;
    }

    .transparent-card {
        background-color: rgba(255,255,255,0.1);
        color: white;
        display: flex;
        flex-direction: column;
        padding: 50px 0px;
        border-radius: 5px;
        width: 49%;
        margin-bottom: 2%;

        :nth-child(1) {
            font-size: 20px;
        }

        :nth-child(2) {
            font-size: 25px;
            font-weight: bold;
        }

        &.big {
            width: 100%;
        }

        &.small {
            width: 32%;
        }
    }

    .title-container {
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-custom {
        padding: 20px;
        background-color: rgba(255,255,255,0.25);

        .work-list {
            margin-top: 10px;

            li {
                text-align: left;
                margin-top: 10px;
            }
        }

        .title {
            text-align: left;
            font-size: 25px;
        }
    }

    .transparent-card {
        background-color: rgba(255,255,255,0.1);
        color: white;
        display: flex;
        flex-direction: column;
        padding: 50px 0px;
        border-radius: 5px;
        width: 49%;
        margin-bottom: 2%;

        :nth-child(1) {
            font-size: 20px;
        }

        :nth-child(2) {
            font-size: 25px;
            font-weight: bold;
        }

        &.big {
            width: 100%;
        }

        &.small {
            width: 32%;
        }
    }

    .discord-user-avatar {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        background-color: transparent;
    }
}


/* MEDIAS */
@media screen and (max-width: 500px) {
    .theme-kida {
        footer > .footer-container,
        section.container {
            padding: 10px;
        }

        .transparent-card {
            width: 100% !important;
            margin-bottom: 15px;
    
            :nth-child(1) {
                font-size: 20px;
            }
    
            :nth-child(2) {
                font-size: 25px;
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 300px) {
    .theme-kida {
    
        footer > .footer-container,
        section.container {
            padding: 5px;
        }
    }
}