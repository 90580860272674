.theme-angel {
    .table-overflow {
        overflow: auto;
        min-height: 500px;
        height: unset !important;
    }
}

@media screen and (max-width: 500px) {
    .theme-angel {
        .table-overflow {
            th,
            td {
                min-width: 200px;
            }
        }
    }
}