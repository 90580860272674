@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700&display=swap");

.theme-hypotonic {

    &.no-scroll {
        max-height: 100vh;
        overflow: hidden;
    }

    --color-red: #f6465d;
    --color-green: rgba(0,255,0,0.7);
    --color-tint: rgba(255,255,255,0.1);

    background: rgb(35, 29, 75);
    color: white;
    
    font-family: Inter,ui-sans-serif,system-ui !important;
    font-weight: 300 !important;
    font-size: 1rem !important;
    line-height: 1.4 !important;
    -ms-overflow-style: scrollbar;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // theme layout
    a {
        color: white;
        text-decoration: none;
    }
    
    strong,
    b {
        font-weight: 600 !important;
    }
    
    p {
        margin: 0 0 2.5rem 0;
    }
    
    h1 {
        font-size: 54px !important;
        font-weight: 800 !important;
        line-height: 1.4 !important;
        margin: 0 0 1.5rem 0;
    }
    
    h2 {
        font-size: 42px !important;
        font-weight: 700 !important;
        line-height: 1.4 !important;
        //margin: 0 0 1.5rem 0;
        text-align: left;
    }
    
    h3 {
        font-size: 28px !important;
        font-weight: 500 !important;
        text-transform: uppercase !important;
        //margin: 0 0 1.5rem 0;
        text-align: left;
    }
    
    h4 {
        font-size: 30px !important;
        font-weight: 400 !important;
        text-transform: capitalize !important;
        margin: 0 0 1.5rem 0;
    }
    
    h5 {
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 1.4 !important;
        margin: 0 0 1.5rem 0;
    }
    
    h6 {
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 1.4 !important;
        margin: 0 0 1.5rem 0;
    }

    footer > .footer-container,
    section.container {
        padding: 15px;
    }

    #footer-data {
        @media screen and (max-width: 900px) {
            display: flex;
            flex-direction: column;
            text-align: right;
            align-items: flex-end;

            span {
                display: none;
            }

            div {
                margin: 0px;

                > svg {
                    margin-top: 15px;
                }
            }

            a {
                margin-top: 5px;
            }
        }

        .flipside-link {
            position: absolute;
            bottom: 0;
        }
    }
    
    .header {
        position: sticky;
        top: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        background-color: rgb(35, 29, 75);
        padding-top: 10px;
        padding-bottom: 10px;
        z-index: 99999;
    }

    #logo {
        width: 50px;
        height: 50px;
    }

    .title-container {
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .transparent-card {
        background-color: rgba(255,255,255,0.1);
        color: white;
        display: flex;
        flex-direction: column;
        padding: 50px 0px;
        border-radius: 5px;
        width: 49%;
        margin-bottom: 2%;

        &.big {
            width: 100%;
        }

        &.small {
            width: 32%;
        }
    }

    #title {
        text-transform: uppercase !important;
    }

    section.content {
        margin-top: 20px;
    }

    .menu-button {
        background-color: transparent;
        outline: none;
        border: none;
        color: white;
        height: 100%;
        width: 50px;
    }

    .sidebar {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 500px;
        max-width: 100vw;

        display: flex;
        flex-direction: column;

        padding: 20px;
        background-color: #39316B;

        z-index: 9999999;

        button {
            background-color: transparent;
            outline: none;
            border: none;
            color: white;

            height: 50px;
            width: 100%;

            margin-top: 10px;

            &.active {
                background-color: var(--color-tint);
                border-radius: 10px;
            }
        }
    }

    .input-container {
        background-color: rgba(255,255,255,0.1);
        display: flex;
        flex-direction: row;
        padding: 10px 10px;
        border-radius: 5px;
        align-items: center;
        width: 100%;
        overflow: hidden;

        i:nth-child(1),
        svg:nth-child(1) {
            margin-right: 10px;
        }

        input {
            margin: 0;
            margin-right: 10px;
            padding: 0;
            border: none !important;
            background-color: transparent;
            outline: none !important;
            color: white;

            width: 100%;
            text-overflow: ellipsis;
        }
    }

    .cat-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        align-items: center;
        height: 100%;
        width: 50%;
        border-bottom: 2px solid rgba(255,255,255,0.7);
        margin-top: calc(25vh - 50px);

        @media screen and (max-width: 500px) {
            width: 90%;
        }
    }

    .chat-bubble-text {
        position: absolute;
        color: black;
        top: 0;
        bottom: 0;
        left: 205px;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1rem;

        @media screen and (max-width: 500px) {
            left: 35vw;
        }
    }

    #chat {
        width: 300px;
        margin-left: 205px;

        @media screen and (max-width: 500px) {
            width: 50vw;
            margin-left: 35vw;
        }
    }

    #cat {
        width: 150px;
    }

    .detail-container {
        display: flex;
        flex-direction: column;
        margin: auto;
        align-items: center;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        padding: 30px;
        border-radius: 15px;

        .table-responsive {
            width: 100%;
        }

        table {
            color: white;
            width: 100%;
            font-size: 12px;

            td {
                height: 50px;
                vertical-align: middle;
                border: none;

                @media screen and (max-width: 500px){
                    min-width: 180px;
                }
            }

            td:first-child,
            th:first-child {
                min-width: 0px;
            }

            td:nth-child(2),
            th:nth-child(2) {
                text-align: left;
            }

            td:not(:first-child):not(:nth-child(2)),
            th:not(:first-child):not(:nth-child(2)) {
                text-align: right;
            }
        }
    }

    .swaps-tab,
    .pools-tab,
    .transfers-tab {
        
        .page-navigator div {
            width: 100px;
        }

        .page-button {
            background-color: transparent;
            color: white;
            font-weight: bold;
            border: none;
        }

        .overall-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .overall {
                width: 33%;
                padding: 20px;
                background-color: rgba(255,255,255,0.1);
                border-radius: 10px;
                margin-bottom: 5px;

                &.big {
                    width: 49.5%;
                }

                @media screen and (max-width: 580px) {
                    width: 100%;
                }
            }
        }
        

        .filter-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            background-color: transparent;
            border-radius: 10px;
            margin-top: 20px;

            @media screen and (max-width: 580px) {
                width: 100%;
                margin-bottom: 10px;
            }

            .input-container {
                width: 33.15%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                background-color: rgba(255,255,255,0.1);
                
                > div:first-child {
                    font-size: 10px;

                    button {
                        background-color: transparent;
                        border: none;
                        color: var(--color-red);
                    }
                }

                .react-datepicker-wrapper {
                    margin-top: 10px;
                    height: 100%;
                    width: 100%;
                }

                select {
                    margin-top: 10px;
                    background-color: transparent;
                    width: 100%;
                    border-color: transparent !important;
                    color: white;
                    outline: none;

                    option {
                        color: black;
                    }
                }
            }
        }

        .swaps-container {
            display: flex;
            flex-direction: column;
            margin: auto;
            align-items: center;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 15px 15px;
            border-radius: 15px;
    
            .table-responsive {
                width: 100%;
            }
    
            table {
                color: white;
                width: 100%;
                font-size: 12px;
    
                tbody {
                    border-top-width: 0px;
    
                    tr:last-child {
                        td {
                            border-bottom: 0 !important;
                            padding-bottom: 0 !important;
                        }
                    }
                }
    
                td {
                    vertical-align: middle;
                    border: none;
                    border-bottom: 1px solid rgba(255,255,255,0.05);
                    padding: 20px 10px 20px 0px;
    
                    @media screen and (max-width: 900px){
                        min-width: 100px;
                    }
    
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                        padding: 20px 0px 20px 0px;
                    }
    
                    strong {
                        &.red {
                            color: var(--color-red);
                        } 
                        &.green {
                            color: var(--color-green);
                        } 
                    }
                }
    
                th {
                    margin: 0px;
                    padding: 0px;
                    padding-bottom: 10px;
                    border-bottom-width: 2px;
                    border-bottom-color: rgba(255,255,255,0.5);
                }
    
                td:nth-child(1),
                th:nth-child(1),
                td:nth-child(2),
                th:nth-child(2) {
                    width: 10%;
                }
    
                td:nth-child(1),
                th:nth-child(1),
                td:nth-child(2),
                th:nth-child(2),
                td:nth-child(3),
                th:nth-child(3) {
                    text-align: left;
                }
    
                td:nth-child(3),
                th:nth-child(3) {
                    @media screen and (max-width: 900px){
                        min-width: 300px;
                    }
                }
    
                td:nth-child(4),
                th:nth-child(4),
                td:nth-child(5),
                th:nth-child(5),
                td:nth-child(6),
                th:nth-child(6) {
                    width: 15%;
                    text-align: right;
                }
            }
        }

    }

    .pools-tab {
        .pools-container {
            display: flex;
            flex-direction: column;
            margin: auto;
            align-items: center;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 15px 15px;
            border-radius: 15px;

            .table-responsive {
                width: 100%;
            }

            table {
                color: white;
                width: 99%;
                font-size: 12px;

                tbody {
                    border-top-width: 0px;

                    tr:last-child {
                        td {
                            border-bottom: 0 !important;
                            padding-bottom: 0 !important;
                        }
                    }
                }

                td {
                    vertical-align: middle;
                    border: none;
                    border-bottom: 1px solid rgba(255,255,255,0.05);
                    padding: 20px 10px 20px 0px;

                    @media screen and (max-width: 900px){
                        min-width: 100px;
                    }

                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                        padding: 20px 0px 20px 0px;
                    }

                    strong {
                        &.red {
                            color: var(--color-red);
                        } 
                        &.green {
                            color: var(--color-green);
                        } 
                    }
                }

                th {
                    margin: 0px;
                    padding: 0px;
                    padding-bottom: 10px;
                    border-bottom-width: 2px;
                    border-bottom-color: rgba(255,255,255,0.5);
                }

                td:not(:nth-child(6)),
                th:not(:nth-child(6)) {
                    width: 10%;
                }
            }
        }

        .pool-stats-container {
            display: flex;
            flex-direction: column;
            margin: auto;
            align-items: center;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 15px 15px;
            border-radius: 15px;

            .table-responsive {
                width: 100%;
            }

            table {
                color: white;
                width: 99%;
                font-size: 12px;

                tbody {
                    border-top-width: 0px;

                    tr:last-child {
                        td {
                            border-bottom: 0 !important;
                            padding-bottom: 0 !important;
                        }
                    }
                }

                td {
                    vertical-align: middle;
                    border: none;
                    border-bottom: 1px solid rgba(255,255,255,0.05);
                    padding: 20px 10px 20px 0px;
                    white-space: pre-wrap;

                    @media screen and (max-width: 900px){
                        min-width: 100px;
                    }

                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                        padding: 20px 0px 20px 0px;
                    }

                    strong {
                        &.red {
                            color: var(--color-red);
                        } 
                        &.green {
                            color: var(--color-green);
                        } 
                    }
                }

                th {
                    margin: 0px;
                    padding: 0px;
                    padding-bottom: 10px;
                    border-bottom-width: 2px;
                    border-bottom-color: rgba(255,255,255,0.5);
                }
            }
        }
    }

    .transfers-tab {
        .filter-container {
            .input-container {
                width: 49.8%;
            }
        }

        .transfers-container {
            display: flex;
            flex-direction: column;
            margin: auto;
            align-items: center;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 15px 15px;
            border-radius: 15px;
    
            .table-responsive {
                width: 100%;
            }
    
            table {
                color: white;
                width: 100%;
                font-size: 12px;
    
                tbody {
                    border-top-width: 0px;
    
                    tr:last-child {
                        td {
                            border-bottom: 0 !important;
                            padding-bottom: 0 !important;
                        }
                    }
                }
    
                td {
                    vertical-align: middle;
                    border: none;
                    border-bottom: 1px solid rgba(255,255,255,0.05);
                    padding: 20px 10px 20px 0px;
    
                    @media screen and (max-width: 900px){
                        min-width: 100px;
                    }
    
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                        padding: 20px 0px 20px 0px;
                    }
    
                    strong {
                        &.red {
                            color: var(--color-red);
                        } 
                        &.green {
                            color: var(--color-green);
                        } 
                    }
                }
    
                th {
                    margin: 0px;
                    padding: 0px;
                    padding-bottom: 10px;
                    border-bottom-width: 2px;
                    border-bottom-color: rgba(255,255,255,0.5);
                }
    
                td:nth-child(1),
                th:nth-child(1),
                td:nth-child(2),
                th:nth-child(2),
                td:nth-child(3),
                th:nth-child(3) {
                    width: 10%;
                }
    
                td:nth-child(1),
                th:nth-child(1),
                td:nth-child(2),
                th:nth-child(2),
                td:nth-child(3),
                th:nth-child(3) {
                    text-align: left;
                }
    
                td:nth-child(4),
                th:nth-child(4) {
                    width: 60%;
                    text-align: center;
                }
            }
        }
    }

}