.theme-osmosis-stats {
    button.input-button {
        outline: none;
        background-color: transparent;
        color: white;
        border: none;
        border-left: solid 2px var(--color-tint);
        height: 30px;
        padding-left: 20px;
    }

    table {
        color: white;
        font-size: 12px;

        &.stat-table {
            position: relative;

            thead tr {
                position: sticky; 
                top: 0px;
                background-color: #231d4b;
            }
        }
    }

    .notes {
        list-style: decimal;
        text-align: left;

        li {
            margin-top: 5px;
        }
    }

    .navigation-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 30px;


        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            width: 200px;
            height: 70px;
            padding: 10px;
            background-color: rgba(255,255,255, 0.1);
            margin-top: 15px;

            &.active {
                background-color: rgba(255,255,255, 0.3);
            }
        }
    }
}