.theme-kida {
    .sushi-gov {
        .pie-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        .pie {
            height: auto;
            width: 45%;
            margin: 10px;
        }
    }
}