.App.theme-kida {
    section.flipsidooor {
        .input-container {
            background-color: rgba(255,255,255,0.1);
            display: flex;
            flex-direction: row;
            padding: 10px 10px;
            border-radius: 5px;
            align-items: center;
            width: 100%;
            overflow: hidden;

            i:nth-child(1),
            svg:nth-child(1) {
                margin-right: 10px;
            }

            input {
                margin: 0;
                margin-right: 10px;
                padding: 0;
                border: none !important;
                background-color: transparent;
                outline: none !important;
                color: white;

                width: 80%;
                text-overflow: ellipsis;

                &:disabled {
                    cursor: no-drop;
                }
            }

            select {
                margin: 0;
                margin-right: 10px;
                padding: 0;
                border: none !important;
                background-color: transparent;
                outline: none !important;
                color: white;

                width: 15%;
                text-overflow: ellipsis;

                option {
                    color: black;
                }
            }
        }

        .page-button {
            background-color: transparent;
            color: white;
            font-weight: bold;
            border: none;
        }

        .page-navigator {
            width: 200px;
        }

        .spinner-container {
            width: 100%;
            margin-top: 30vh;
        }

        .unverified-container {
            height: 100%;
            width: 100%;
            margin-top: 100px;
            text-align: left;

            ul {
                margin-top: 50px;
                list-style: decimal;

                a {
                    color: rgb(95, 218, 255);
                }
            }

            /* button {
                color: white;
            } */
        }

        .query-result {
            background-color: rgba(255,255,255,0.1);
            display: flex;
            flex-direction: row;
            border-radius: 5px;
            align-items: center;
            width: 100%;
            overflow: hidden;
            margin-bottom: 5px;

            > div {
                background-color: rgba(255,255,255,0.1);
                color: white;
            }

            .accordion-header i,
            .accordion-header svg {
                margin-left: 10px;

                &.fa-eye,
                &.fa-eye-slash {
                    color: white;
                }

                &.fa-trophy {
                    color: gold;
                }

                &.fa-circle.red {
                    color: rgba(255,0,0,0.7);
                }

                &.fa-circle.green {
                    color: rgba(0,255,0,0.7);
                }
            }

            .statement-container,
            .dashboard-container,
            .tables-container,
            .visuals-container {
                position: relative;
                font-size: 13px;
                padding: 10px;
                background-color: rgba(0,0,0,0.1);
                border-radius: 5px;
                margin-bottom: 20px;
                margin-top: 20px;

                > div:first-child {
                    position: absolute;
                    top: -8px;
                    left: 10px;
                    height: 20px;
                    font-size: 10px;
                }

                code {
                    color: white;
                    white-space: pre-wrap;
                }
            }

            .dashboard-container {
                margin-top: 0px;
            }

            .dashboard-container {
                > ul {
                    list-style: decimal;
                    font-size: 13px;
                    margin-bottom: 10px;

                    > li {
                        margin-top: 10px; 

                        > ul {
                            list-style: lower-roman;
                        }
                    }
                }
            }

            .visuals-container {
                padding-top: 20px;
                .visual-iframe {
                    height: 650px;
                }
            }
        }
    }
}