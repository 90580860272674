.theme-angel {
    background-image: linear-gradient(180deg,#3385bd,#252626);
    color: white;

    a {
        color: white;
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: Montserrat,serif;
    }

    footer > .footer-container,
    section.container {
        max-width: 900px;
        padding: 15px;
    }
    
    .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    #logo {
        width: 150px;
        height: 70.25px;
    }

    .content {
        margin-top: 10px;

        h1 {
            margin-top: 50px;
            margin-bottom: 20px;
            text-align: left;
            padding-left: 7px;
            font-size: 1.875rem;
        }

        p {
            text-align: left;
            padding-left: 7px;
        }

        h3 {
            margin-top: 50px;
            margin-bottom: 20px;
            text-align: left;
            padding-left: 7px;
        }

        .max-width-button {
            width: 100%;
            max-width: 600px;
        }

        .address-input {
            max-width: 70%;
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
        }

        .table-container {
            position: relative;
            min-height: 500px;

            .page-container {
                padding: 5px;
                display: flex;
                flex-direction: row;
                color: black;
                align-items: center;

                > span {
                    margin-right: 5px;
                }

                .btn:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    .card {
        color: black;

        .card-title {
            text-align: left;
            font-weight: bold;
        }
    }

    .transparent-card {
        background-color: rgba(255,255,255,0.1);
        color: white;
        display: flex;
        flex-direction: column;
        padding: 50px 0px;
        border-radius: 5px;
        width: 49%;
        margin-bottom: 2%;

        :nth-child(1) {
            font-size: 20px;
        }

        :nth-child(2) {
            font-size: 25px;
            font-weight: bold;
        }

        &.big {
            width: 100%;
        }

        &.small {
            width: 32%;
        }
    }
}

/* MEDIAS */
@media screen and (max-width: 500px) {
    .theme-angel {
        footer > .footer-container,
        section.container {
            padding: 10px;
        }

        .transparent-card {
            width: 100% !important;
            margin-bottom: 15px;
    
            :nth-child(1) {
                font-size: 20px;
            }
    
            :nth-child(2) {
                font-size: 25px;
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 300px) {
    .theme-angel {
    
        footer > .footer-container,
        section.container {
            padding: 5px;
        }
    }
}
